.login-body .login-panel {
    img {
        width: 100%;
    }
}

.layout-wrapper .topbar .logo {
    width: 257px;
    background-color: #fff;
    padding-top: 4px !important;

    img {
        width: 257px;
    }

    .layout-main {
        padding: 0px 15px 0px 15px;
    }
}

@media (max-width: 640px) {
    .layout-wrapper {
        .topbar {
            .logo {
                width: 262px;
                padding-top: 20px;

                img {
                    width: 257px;
                }
            }
        }
    }
}
