/*$topbarBgColor:#58799f;
$topbarBgColorDark:#3b5471;
$badgeColor:#e42a7b;
$primaryColor:#58799f;
*/
/*===========================
VARIABLES*/

$fontSize:13px;
$fontFamily:'Open Sans','Helvetica Neue',sans-serif;
$textColor:#2d353c;
$textSecondaryColor:lighten($textColor,25%);
$borderRadius:2px;
$dividerColor:#e3e3e3;
$iconWidth:16px;
$iconHeight:16px;
$highlightTextColor:#ffffff;
$transitionDuration:.3s;

/* Layout */
$bodyBgColor:#f1f3f6;
$logoBgColor:#2d353c;
$footerBgColor:#dee0e3;

/* Topbar */
$topbarMenuTextColor:#ffffff;
$topbarMenuTextHoverColor:#e3e3e3;
$topbarSubmenuBgColor:#f7f7f7;
$topbarSubmenuItemHoverBgColor:#e3e3e3;

/* Menu */
$menuItemHoverBgColor:#e3e3e3;
$darkSidemenuBgColor:#545b61;
$darkMenuBgColor:#63696e;
$darkMenuItemTextColor:#dee0e3;
$darkMenuItemHoverBgColor:#767b7f;
$menuitemColor:#545b61;

/* List Items */
$listItemPadding:.5em 1em;

/* Predefined Colors */
$orange:#ff9c59;
$green:#5ea980;
$steel:#58799f;

/*===========================
MIXINS*/

@mixin border-radius($val) {
    -moz-border-radius: $val;
    -webkit-border-radius: $val;
    border-radius: $val;
}

@mixin border-radius-right($val) {
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin border-radius-left($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
}

@mixin border-radius-top($val) {
    -moz-border-radius-topleft: $val;
    -webkit-border-top-left-radius: $val;
    border-top-left-radius: $val;
    -moz-border-radius-topright: $val;
    -webkit-border-top-right-radius: $val;
    border-top-right-radius: $val;
}

@mixin border-radius-bottom($val) {
    -moz-border-radius-bottomleft: $val;
    -webkit-border-bottom-left-radius: $val;
    border-bottom-left-radius: $val;
    -moz-border-radius-bottomright: $val;
    -webkit-border-bottom-right-radius: $val;
    border-bottom-right-radius: $val;
}

@mixin transition($transition...) {
    -moz-transition:    $transition;
    -o-transition:      $transition;
    -webkit-transition: $transition;
    transition:         $transition;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -o-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin scale($deg) {
    -webkit-transform: scale($deg);
    -moz-transform: scale($deg);
    -o-transform: scale($deg);
    -ms-transform: scale($deg);
    transform: scale($deg);
}

@mixin opacity($opacity) {
  opacity: $opacity;
  $opacity-ie: $opacity * 100;
  filter: alpha(opacity=$opacity-ie);
}

@mixin no-shadow() {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
}

@mixin list-item-hover {
    background-color: $listItemHoverBgColor;
    color: $listItemHoverTextColor;
}

@mixin list-item-highlight {
    background-color: $primaryColor;
    color: $highlightTextColor;
}

@mixin menu-item-hover {
    background-color: $primaryColor;
    color: $highlightTextColor;
}

@mixin background-gradient-top2bottom($start-colour, $end-colour) {
    background-color: $start-colour;
    background-image: -webkit-gradient(linear, left top, left bottom, from($start-colour), to($end-colour));
    background-image: -webkit-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:    -moz-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:     -ms-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:      -o-linear-gradient(to bottom, $start-colour, $end-colour);
    background-image:         linear-gradient(to bottom, $start-colour, $end-colour);
    filter:            progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$start-colour}', endColorstr='#{$end-colour}');
}

@mixin border-shadow() {
    -webkit-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
}

@mixin overlay-shadow {
    -webkit-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

@mixin placeholder {
    &::-webkit-input-placeholder {@content}
    &:-moz-placeholder           {@content}
    &::-moz-placeholder          {@content}
    &:-ms-input-placeholder      {@content}
}

@mixin link-focus-shadow() {
    -webkit-box-shadow: 0 0 4px 0 $primaryColor;
    -moz-box-shadow: 0 0 4px 0 $primaryColor;
    box-shadow: 0 0 4px 0 $primaryColor;
}
@mixin flex() {
    display: -ms-flexbox;
    display: flex;
}

@mixin flex-wrap($value) {
    -ms-flex-wrap: $value;
    flex-wrap: $value;
}

@mixin flex-direction-row() {
    -ms-flex-direction: row;
    flex-direction: row;
}

@mixin flex-direction-column() {
    -ms-flex-direction: column;
    flex-direction: column;
}

@mixin flex-justify-center() {
    -ms-flex-pack: center;
    justify-content: center;
}

@mixin flex-justify-between() {
    -ms-flex-pack: justify;
    justify-content: space-between;
}

@mixin flex-align-center() {
    -ms-flex-align: center;
    align-items: center;
}

@mixin flex-align-start() {
    -ms-flex-align: start;
    align-items: flex-start;
}

@mixin flex-align-end() {
    -ms-flex-align: end;
    align-items: flex-end;
}

@mixin flex-justify-start() {
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@mixin flex-justify-end() {
    -ms-flex-pack: end;
    justify-content: flex-end;
}

/*===========================
FONTS*/

/* open-sans-300 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url("/assets/layout/fonts/open-sans-v13-latin-300.eot"); /* IE9 Compat Modes */
    src: local('Open Sans Light'), local('OpenSans-Light'),
         url("/assets/layout/fonts/open-sans-v13-latin-300.eot?#iefix") format('embedded-opentype'), /* IE6-IE8 */
         url("/assets/layout/fonts/open-sans-v13-latin-300.woff2") format('woff2'), /* Super Modern Browsers */
         url("/assets/layout/fonts/open-sans-v13-latin-300.woff") format('woff'), /* Modern Browsers */
         url("/assets/layout/fonts/open-sans-v13-latin-300.ttf") format('truetype'), /* Safari, Android, iOS */
         url("/assets/layout/fonts/open-sans-v13-latin-300.svg#OpenSans") format('svg'); /* Legacy iOS */
  }
  /* open-sans-regular - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url("/assets/layout/fonts/open-sans-v13-latin-regular.eot"); /* IE9 Compat Modes */
    src: local('Open Sans'), local('OpenSans'),
         url("/assets/layout/fonts/open-sans-v13-latin-regular.eot#iefix") format('embedded-opentype'), /* IE6-IE8 */
         url("/assets/layout/fonts/open-sans-v13-latin-regular.woff2") format('woff2'), /* Super Modern Browsers */
         url("/assets/layout/fonts/open-sans-v13-latin-regular.woff") format('woff'), /* Modern Browsers */
         url("/assets/layout/fonts/open-sans-v13-latin-regular.ttf") format('truetype'), /* Safari, Android, iOS */
         url("/assets/layout/fonts/open-sans-v13-latin-regular.svg#OpenSans") format('svg'); /* Legacy iOS */
  }
  /* open-sans-700 - latin */
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url("/assets/layout/fonts/open-sans-v13-latin-700.eot"); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
         url("/assets/layout/fonts/open-sans-v13-latin-700.eot#iefix") format('embedded-opentype'), /* IE6-IE8 */
         url("/assets/layout/fonts/open-sans-v13-latin-700.woff2") format('woff2'), /* Super Modern Browsers */
         url("/assets/layout/fonts/open-sans-v13-latin-700.woff") format('woff'), /* Modern Browsers */
         url("/assets/layout/fonts/open-sans-v13-latin-700.ttf") format('truetype'), /* Safari, Android, iOS */
         url("/assets/layout/fonts/open-sans-v13-latin-700.svg#OpenSans") format('svg'); /* Legacy iOS */
  }

/*===========================
UTILS*/

/* Utils */
.clearfix:after {
    content: " ";
    display: block;
    clear: both;
}

.card {
    @include border-radius(2px);
    background: #ffffff;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;

    &:last-child {
        margin-bottom: 0;
    }

    .card-header {
        @include flex();
        @include flex-align-center();
        @include flex-justify-between();
    }

    .card-subtitle {
        color: $textSecondaryColor;
        font-weight: 600;
        margin: -1rem 0 1rem 0;
    }
}

.p-toast {
    &.p-toast-top-right,
    &.p-toast-top-left,
    &.p-toast-top-center {
        top: 70px;
    }
}

@-webkit-keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translate3d(0, -20px, 0);
    }

    to {
        opacity: 1;
        transform: none;
    }
}

@-webkit-keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
}

@keyframes fadeOutUp {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
        -webkit-transform: translate3d(0, -20px, 0);
        transform: translate3d(0, -20px, 0);
    }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

/*===========================
LOGIN*/

.login-body {
    background-color: #2d353c;
    padding-top: 100px;
    box-sizing: border-box;
    min-height: 100vh;

    .login-panel {
        background-color: #f7f7f7;
        width: 460px;
        margin: 0 auto;
        text-align: center;
        padding: 60px 100px;
        box-sizing: border-box;
        border-bottom: 15px solid #3192e1;

        h1 {
            font-size: 22px;
            color: #2d353c;
        }

        h2 {
            font-size: 13px;
            color: #b3b3b3;
        }

        img {
            width: 130px;
        }

        button {
            display: block;
            margin-bottom: 10px;
        }

        label {
            color: #767b7f;
            vertical-align: middle;
            margin-left: 6px;
        }
    }
}

@media (max-width: 640px) {
    .login-body {
        padding-top: 40px;

        .login-panel {
            width: 320px;
            padding: 30px 50px;
        }
    }
}


/*===========================
MAIN*/

html {
    height: 100%;
    font-size: $fontSize;
}

body {
    font-family: $fontFamily;
    font-size: $fontSize;
    color: $textColor;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding:0;
    margin:0;
    min-height: 100%;
    background-color: $bodyBgColor;

    a {
        color: $primaryColor;
        text-decoration: none;
    }
}

.layout-wrapper {

    .topbar {
        position: fixed;
        width: 100%;
        background-color: $topbarBgColor;
        height: 60px;
        box-sizing: border-box;
        z-index: 100;
        -moz-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.2);

        .logo {
            width: 60px;
            height: 60px;
            background-color: $topbarBgColorDark;
            display: inline-block;
            vertical-align: middle;
            text-align: center;
            padding-top: 15px;
            box-sizing: border-box;

            img {
                width: 50px;
                display: inline-block;
            }
        }

        .app-name {
            vertical-align: middle;
            display: inline-block;
            margin-left: 15px;
            height: 25px;
        }

        #topbar-menu-button {
            font-size: 28px;
            margin: 12px 20px 0 0;
            display: none;
            float: right;
            color: $topbarMenuTextColor;
            @include transition(color .3s);

            &:hover {
                color: $topbarMenuTextHoverColor;
            }
        }

        .topbar-menu {
            float: right;
            margin: 15px 10px 0 0;
            padding: 0;
            list-style-type: none;

            > li {
                float: right;
                position: relative;
                margin-left: 15px;

                > a {
                    position: relative;
                    display: block;;
                    color: $topbarMenuTextColor;

                    .topbar-icon {
                        font-size: 28px;
                        @include transition(color .3s);

                        &:hover {
                            color: $topbarMenuTextHoverColor;
                        }
                    }

                    .topbar-item-name {
                        display: none;
                    }

                    .topbar-badge {
                        position: absolute;
                        right: -5px;
                        top: -5px;
                        background-color: $badgeColor;
                        color: #ffffff;
                        padding: 2px 4px;
                        display: block;
                        font-size: 12px;
                        line-height: 12px;
                        @include border-radius($borderRadius);
                    }
                }

                &.profile-item {
                    margin-top: -5px;

                    .profile-image {
                        display: inline-block;
                        vertical-align: middle;
                        img {
                            width: 36px;
                            height: 36px;
                        }
                    }

                    .profile-info {
                        display: inline-block;
                        vertical-align: middle;
                        max-width: 100px;
                        margin-top: -5px;

                        .topbar-item-name {
                            display: block;

                            &.profile-name {
                                font-size: $fontSize - 2;
                            }

                            &.profile-role {
                                font-size: $fontSize - 3;
                                color: darken($topbarMenuTextColor,10%);
                            }
                        }
                    }
                }

                &.search-item {
                    position: relative;
                    color: $topbarMenuTextColor;

                    i {
                        position: absolute;
                        left: 6px;
                        top: 9px;
                    }

                    input {
                        color: $topbarMenuTextColor;
                        padding-left: 20px;
                        border-color: $topbarMenuTextColor;
                        background-color: $topbarBgColor;

                        &:hover,&:focus {
                            border-color: $topbarMenuTextHoverColor;
                        }
                    }
                }

                > ul {
                    position: absolute;
                    top: 45px;
                    right: 5px;
                    display: none;
                    width: 250px;
                    background-color: $topbarSubmenuBgColor;
                    -webkit-animation-duration: .5s;
                    -moz-animation-duration: .5s;
                    animation-duration: .5s;
                    list-style-type: none;
                    margin: 0;
                    padding: 8px 0;
                    @include overlay-shadow();

                    a {
                        padding: $listItemPadding;
                        display: block;
                        width: 100%;
                        box-sizing: border-box;
                        color: $textColor;

                        i {
                            margin-right: 8px;
                        }

                        img {
                            margin-right: 8px;
                        }

                        i,img,span {
                            vertical-align: middle;
                        }

                        .topbar-submenuitem-badge {
                            background-color: $badgeColor;
                            padding: 1px 6px;
                            color: #ffffff;
                            float: right;
                        }

                        &:hover {
                            background-color: $topbarSubmenuItemHoverBgColor;
                            @include transition(background-color .3s);
                        }
                    }
                }

                &.active-topmenuitem {
                    > ul {
                        display: block;
                    }
                }
            }
        }
    }

    .layout-sidebar {
        position: fixed;
        top: 60px;
        width: 60px;
        background-color: #dee0e3;
        height: 100%;
        -webkit-box-shadow: 3px 0px 10px 0 rgba(0, 0, 0, 0.20);
        -moz-box-shadow: 3px 0px 10px 0 rgba(0, 0, 0, 0.20);
        box-shadow: 3px 0px 10px 0 rgba(0, 0, 0, 0.20);
        z-index: 100;
        @include transition(width .3s);

        .layout-tabmenu {
            height: 100%;
            width: 60px;
            position: relative;

            .layout-tabmenu-nav {
                margin: 0;
                padding: 0;
                display: block;
                z-index: 100;

                li {
                    list-style-type: none;
                    text-align: center;
                    box-sizing: border-box;
                    position: relative;

                    a {
                        display: block;
                        padding: 8px 0;
                        height: 40px;
                        width: 100%;
                        box-sizing: border-box;
                        border-bottom: 1px solid #c7c7c7;

                        i {
                            @include transition(color .3s);
                            color: #999999;
                            font-size: 24px;
                        }

                        &:hover {
                            + .layout-tabmenu-tooltip {
                                display: block;
                            }

                            i {
                                color: #7a7a7a;
                            }
                        }
                    }

                    .layout-tabmenu-tooltip {
                        display:none;
                        padding: 0 5px;
                        position: absolute;
                        left: 60px;
                        top: 10px;
                        z-index: 101;
                        line-height: 1;

                        .layout-tabmenu-tooltip-text {
                           padding: 3px 10px;
                           background-color: #545b61;
                           color: #ffffff;
                           min-width: 75px;
                           @include overlay-shadow();
                        }

                        .layout-tabmenu-tooltip-arrow {
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-color: transparent;
                            border-style: solid;
                            top: 50%;
                            left: 0;
                            margin-top: -5px;
                            border-width: 5px 5px 5px 0;
                            border-right-color: #545b61;
                        }
                    }

                    &.active-item {
                        border-left: 4px solid $primaryColor;
                        background-color: #ebebee;

                        a {
                            i {
                                color: $textColor;
                                margin-left: -4px;
                            }

                            .layout-tabmenu-tooltip {
                                left: 56px;
                            }
                        }
                    }
                }
            }

            .layout-tabmenu-contents {
                width: 200px;
                position: absolute;
                top: 0;
                left: 60px;
                display: none;
                background-color: #ebebee;
                height: 100%;

                .layout-tabmenu-content {
                    height: 100%;
                    display: none;

                    &.layout-tabmenu-content-active {
                        display: block;
                    }

                    a {
                        color: $menuitemColor;

                        .menuitem-badge {
                            position: relative;
                            float: right;
                            display: inline-block;
                            width: 16px;
                            height: 16px;
                            text-align: center;
                            background-color: $primaryColor;
                            color: $highlightTextColor;
                            font-size: $fontSize - 2;
                            font-weight: 700;
                            line-height: 16px;
                            @include border-radius(50%);
                        }
                    }

                    .layout-submenu-title {
                        padding: 10px 12px;
                        height: 40px;
                        box-sizing: border-box;
                        border-bottom: 1px solid #c7c7c7;
                        font-size: 16px;

                        .menu-button {
                            padding: 0px 4px;
                            float: right;
                            @include transition(background-color .3s);

                            &:hover {
                                background-color: $menuItemHoverBgColor;
                            }
                        }
                    }

                    .layout-submenu-content {
                        padding: 6px 0px 6px 6px;
                        height: 100%;
                        overflow: auto;

                        .menu-scroll-content {
                            padding-bottom: 150px;
                        }

                        .navigation-menu {
                            margin: 0;
                            padding: 0;
                            list-style-type: none;

                            li {
                                > a {
                                    display: block;
                                    padding: 6px;
                                    cursor: pointer;
                                    user-select: none;
                                    @include transition(background-color .3s);

                                    i:first-child {
                                        @include transition(color .3s);
                                        color: #999999;
                                        margin-right: 4px;
                                    }

                                    &.rotated-icon {
                                        .layout-menuitem-icon {
                                            transform: rotate(90deg);
                                        }
                                    }

                                    .layout-menuitem-toggler {
                                        float: right;
                                        margin-top: 2px;
                                        @include transition(transform .3s);
                                    }

                                    &:hover {
                                        background-color:$menuItemHoverBgColor;
                                        i:first-child {
                                            color: #545b61;
                                        }
                                    }

                                    &.active-menuitem-routerlink {
                                        color: $primaryColor;

                                        i:first-child {
                                            color: $primaryColor;
                                        }
                                    }
                                }

                                > ul {
                                    margin: 0;
                                    padding: 0 0 0 18px;
                                    list-style-type: none;
                                    overflow: hidden;
                                }

                                &.active-menuitem {
                                    > a {
                                        color: $primaryColor;

                                        i:first-child {
                                            color: $primaryColor;
                                        }

                                        .layout-menuitem-toggler {
                                            @include rotate(-180deg);
                                        }
                                    }
                                }

                                &.orange-badge {
                                    > a {
                                        .menuitem-badge {
                                            background-color: $orange;
                                            color: $highlightTextColor;
                                        }
                                    }
                                }

                                &.steel-badge {
                                    > a {
                                        .menuitem-badge {
                                            background-color: $steel;
                                            color: $highlightTextColor;
                                        }
                                    }
                                }

                                &.green-badge {
                                    > a {
                                        .menuitem-badge {
                                            background-color: $green;
                                            color: $highlightTextColor;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.layout-sidebar-dark {
            background-color: $darkSidemenuBgColor;

            .layout-tabmenu {
                .layout-tabmenu-nav {
                    li {
                        a {
                            border-bottom: 1px solid #767b7f;

                            i {
                                color: #8f8f8f;
                            }

                            &:hover {
                                i {
                                    color: #b3b3b3;
                                }
                            }
                        }

                        &.active-item {
                            border-left: 4px solid $primaryColor;
                            background-color: #ebebee;

                            a {
                                background-color: $darkMenuBgColor;

                                i {
                                    color: $darkMenuItemTextColor;
                                    margin-left: -4px;
                                }
                            }
                        }
                    }
                }

                .layout-tabmenu-contents {
                    background-color: $darkMenuBgColor;

                    .layout-tabmenu-content {
                        .layout-submenu-title {
                            color: $darkMenuItemTextColor;
                            border-bottom-color: #82878b;

                            .menu-button {
                                color: $darkMenuItemTextColor;

                                &:hover {
                                    background-color: $darkMenuItemHoverBgColor;
                                }
                            }
                        }
                    }

                    .layout-submenu-content {
                        .navigation-menu {
                            li {
                                > a {
                                    color: $darkMenuItemTextColor;

                                    i:first-child {
                                        color: $darkMenuItemTextColor;
                                    }

                                    &:hover {
                                        background-color: $darkMenuItemHoverBgColor;

                                        i:first-child {
                                            color: $darkMenuItemTextColor;
                                        }
                                    }

                                    &.active-menuitem-routerlink {
                                        color: lighten($primaryColor,15%);

                                        i:first-child {
                                            color: lighten($primaryColor,15%);
                                        }
                                    }
                                }

                                &.active-menuitem {
                                    > a {
                                        color: lighten($primaryColor,15%);

                                        i:first-child {
                                            color: lighten($primaryColor,15%);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.layout-wrapper-menu-active {
        .layout-tabmenu {
            .layout-tabmenu-nav {
                > li.active-item {
                    > a:hover {
                        + .layout-tabmenu-tooltip {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .layout-main {
        @include flex();
        @include flex-direction-column();
    //    @include flex-justify-between();
        min-height: 100vh;
        margin-left: 60px;
        padding: 0px 15px 0px 15px;
        @include transition(margin-left .3s);

        .layout-main-content {
            flex: 1 1 0;
            padding-bottom: 15px;
        }
    }

    .footer {
        background-color: $footerBgColor;
        padding: 8px 14px;

        img {
            width: 36px;
            margin-right: 8px;
            vertical-align: middle;
        }

        span {
            vertical-align: middle;
        }

        a {
            float: right;
            font-size: 16px;
            margin-left: 8px;
            display: block;
            color: $textColor;

            i {
                font-size: 1em;
            }
        }

    }
}

@media (min-width: 641px) {
    .layout-wrapper {
        &.layout-wrapper-menu-active {
            .layout-sidebar {
                width: 260px;

                .layout-tabmenu {
                    .layout-tabmenu-contents {
                        display: block;
                    }
                }
            }

            .layout-main {
                margin-left: 260px;
            }
        }

        &.layout-overlay-menu {
            &.layout-wrapper-menu-active {
                .layout-main {
                    margin-left: 60px;
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .layout-wrapper {
        .topbar {
            #topbar-menu-button {
                display: block;

                i {
                    font-size: 24px;
                }
            }

            .topbar-menu {
                position: absolute;
                top: 60px;
                right: 15px;
                width: 250px;
                -webkit-animation-duration: .5s;
                -moz-animation-duration: .5s;
                animation-duration: .5s;
                display: none;
                background-color: $topbarSubmenuBgColor;
                list-style-type: none;
                margin: 0;
                padding: 8px 0;
                @include overlay-shadow();

                > li {
                    box-sizing: border-box;
                    width: 100%;
                    margin: 0;
                    float: none;

                    > a {
                        font-size: $fontSize;
                        width: 100%;
                        display: block;
                        box-sizing: border-box;
                        color: $textColor;
                        padding: $listItemPadding;
                        position: relative;
                        @include transition(background-color .3s);

                        .topbar-icon {
                            display: inline-block;
                            vertical-align: middle;
                            margin-right: 8px;
                            font-size: 20px;
                        }

                        &:hover {
                            background-color: $topbarSubmenuItemHoverBgColor;
                        }

                        .topbar-item-name {
                            display: inline-block;
                            vertical-align: middle;
                        }

                        .topbar-badge {
                            position: absolute;
                            right: 10px;
                            top: 8px;
                            padding: 2px 4px;
                            display: block;
                            font-size: 12px;
                            line-height: 12px;
                        }
                    }

                    > ul {
                        -webkit-animation-name: none;
                        animation-name: none;
                        display: none;
                        list-style-type: none;
                        padding: 0 0 0 18px;
                        margin: 0;
                        position: static;
                        top: auto;
                        left: auto;
                        box-sizing: border-box;
                        width: 100%;
                        @include no-shadow();

                        li {
                            a {
                                padding: $listItemPadding;
                                display: block;
                                width: 100%;
                                box-sizing: border-box;

                                span, img, i {
                                    display: inline-block;
                                    vertical-align: middle;
                                }

                                img {
                                    width: 1.28571429em;
                                    margin-right: 8px;
                                }

                                i {
                                    margin-right: 8px;
                                }
                            }
                        }
                    }

                    &.active-topmenuitem {


                        > ul {
                            display: block;
                        }
                    }

                    &.profile-item {
                        .profile-image {
                            img {
                                display: inline-block;
                                vertical-align: middle;
                                width: 24px;
                                height: 24px;
                                margin-right: 8px;
                            }
                        }

                        .profile-info {
                            .topbar-item-name {
                                &.profile-name {
                                    vertical-align: middle;
                                    font-size: $fontSize;
                                }

                                &.profile-role {
                                    display: none;
                                }
                            }
                        }
                    }

                    &.search-item {
                        text-align: center;
                        i {
                            color: $textColor;
                            left: 20px;
                        }

                        input {
                            color: $textColor;
                            border: 0 none;
                            border-bottom: 1px solid #c7c7c7;
                            background: transparent;
                            width: 90%;
                            box-sizing: border-box;
                            padding-left: 30px;

                            &:enabled:focus {
                                @include border-radius(0);
                                @include no-shadow();
                                border-bottom: 1px solid $primaryColor;
                            }
                        }
                    }
                }

                &.topbar-menu-visible {
                    display: block;
                }
            }
        }

        &.layout-wrapper-menu-active {
            .layout-sidebar {
                width: 260px;

                .layout-tabmenu {
                    .layout-tabmenu-contents {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (max-width: 640px) {
    .layout-wrapper {
        .layout-sidebar {
            width: 40px;

            .layout-tabmenu {
                width: 40px;

                .layout-tabmenu-nav {
                    li {
                        a {
                            i {
                                font-size: 20px;
                            }
                        }

                        .layout-tabmenu-tooltip {
                            left: 40px;
                        }
                    }
                }

                .layout-tabmenu-contents {
                    left: 40px;
                }
            }
        }

        &.layout-wrapper-menu-active {
            .layout-sidebar {
                width: 240px;
            }
        }

        .layout-main {
            margin-left:40px;
        }
    }
}


/*===========================
SPLASH*/

.splash-screen {
    background: #3192e1;
    width: 100%;
    height: 100%;
    position: fixed;

    .centered {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    .logo {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 20px;
    }

    .loader {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 2rem;
        width: 5rem;
        height: 5rem;
        border-right: 4px solid #ffffff;
        border-radius: 100%;
        animation: spinRight 2400ms linear infinite;

        &:before, &:after {
        content: '';
        width: 5rem;
        height: 5rem;
        display: block;
        position: absolute;
        top: calc(50% - 2.5rem);
        left: calc(50% - 2.5rem);
        border-left: 3px solid #ffffff;
        border-radius: 100%;
        animation: spinLeft 2400ms linear infinite;
        }

        &:after {
        width: 2rem;
        height: 2rem;
        top: calc(50% - 1rem);
        left: calc(50% - 1rem);
        border: 0;
        border-right: 2px solid #ffffff;
        animation: none;
        }
    }
}

@keyframes spinLeft {
    from {transform:rotate(0deg);}
    to {transform:rotate(720deg);}
}

@keyframes spinRight {
    from {transform:rotate(360deg);}
    to {transform:rotate(0deg);}
}

@keyframes rotate {
0% {
  transform: rotateZ(0deg);
}
100% {
  transform: rotateZ(360deg);
 }
}

/*===========================
TYPOGRAPHY*/

h1, h2, h3, h4, h5, h6 {
    margin: 1.5rem 0 1rem 0;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.2;
    color: inherit;

    &:first-child {
        margin-top: 0;
    }
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem;
}

h5 {
    font-size: 1.25rem;
}

h6 {
    font-size: 1rem;
}

mark {
    background: #FFF8E1;
    padding: .25rem .4rem;
    border-radius: $borderRadius;
    font-family: monospace;
}

.monospace {
    font-family: monospace !important;
}

blockquote {
    margin: 1rem 0;
    padding: 0 2rem;
    border-left: 4px solid #90A4AE;
}

hr {
    border-top: solid $dividerColor;
    border-width: 1px 0 0 0;
    margin: 1rem 0;
}

p {
    margin: 0 0 1rem 0;
    line-height: 1.5;

    &:last-child {
        margin-bottom: 0;
    }
}

