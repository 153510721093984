/* You can add global styles to this file, and also import other style files */

@use 'sass:color';
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

@import './assets/corporate-design.scss';
@import "./layout.scss";
@import "./assets/layout-customer.scss";
@import "./theme.scss";
@import "./colors.scss";

.body {
    font-size: 14px !important;
}

.sm-card {
    border-radius: 8px !important;

    a {
        color: white;
    }
}

.dashboard-card-main {
    padding: 15px;
    border-radius: 8px;
    background: white;
}

.toolbar-element {
    margin-right: 4px;
}

.align-left {
    text-align: left;
}

.width100 {
    width: 100px;
}

.width200 {
    width: 200px;
}

.width300 {
    width: 300px;
}

.width400 {
    width: 400px;
}

.width50p {
    width: 50%;
}

.width100p {
    width: 100%;
}

.height100p {
    height: 100%;
}

.hidden-take-space {
    visibility: hidden;
}

[app-disabled] {
    opacity: 0.5;
    pointer-events: none;
}

.badge {
    border-radius: 2px;
    padding: .25em .5em;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.badge-yes {
    background-color: #c8e6c9;
    color: #256029;
}

.badge-no {
    background-color: #ffcdd2;
    color: #c63737;
}

.badge-mid {
    background-color: #ffe2b0;
    color: #404040;
}

.profile-info {
    max-width: 200px !important;
}

.mouse-pointer {
    cursor: pointer;
}

.mouse-default {
    cursor: default;
}

.primary-color {
    background-color: $primaryColor;
    color: white;
}

.primary-color-lighter {
    background-color: color.adjust($primaryColor , $lightness: +15%);
}

.primary-color-darker {
    background-color: color.adjust($primaryColor , $lightness: -15%);
}

.field-label {
    margin-right: 10px;
}

.field-label2 {
    margin-left: 10px;
    margin-right: 10px;
}

.p-steps .p-steps-item .p-menuitem-link {
    background: transparent !important;
}

.single-page-view {
    height: calc(100vh - 100px);
    overflow-y: hidden;
}

.single-page-view-less {
    height: calc(100vh - 300px);
    overflow-y: hidden;
}
.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
}

.p-jc-center {
    justify-content: center;
}

.center-child {
    display: flex;
    justify-content: center;
}

.p-avatar.p-avatar-sm {
    width: 1.5rem;
    height: 1.5rem;
    font-size: 0.75rem;
}

.p-avatar.p-avatar-sm .p-avatar-icon {
    font-size: 0.75rem;
}

.p-tooltip {
    max-width: 50rem !important;
}

.p-dialog-bottom .p-dialog {
    margin: 0 !important;
}

.text-green {
    color: green;
}

@media (max-width: 640px) {
    .hide-mobile {
        display: none;
    }
}

p-sorticon i.p-sortable-column-icon.pi-sort-alt {
    display: none;
}

.compact .p-component {
    font-size: 12px !important;
}

.compact .p-tree {
    padding: 0.3rem !important;
}

.compact .p-inputtext {
    padding: 0.3rem !important;
}

//InputText innerhalb InputGroup
.compact span.p-input-icon-left .p-inputtext {
    padding-left: 2rem !important;
}

.compact .p-multiselect-label {
    padding: 0.3rem !important;
}

.compact .p-checkbox {
    width: 18px !important;
    height: 18px !important;
}

.compact .p-checkbox-box {
    width: 18px !important;
    height: 18px !important;
}

.compact .p-tabview-nav-link {
    padding: 0.75rem !important;
}

.compact sm-table-cell .p-button {
    padding: 0rem 0rem !important;
    width: 1.5rem;
}

.compact .p-button {
    padding: 0.3rem 0.5rem !important;
}

.compact .p-button .p-button-icon {
    font-size: 11px !important;
}

.compact .p-treenode {
    padding: 0rem !important;
}

.compact .p-treenode-content {
    padding: 0.1rem !important;
}

.compact .p-tree-toggler {
    height: 1.5rem !important;
}

.compact .p-listbox-list {
    padding: 0rem !important;
}

.compact .p-listbox-item {
    padding: 0rem 0.5rem !important;
}

.compact tr td {
    padding: 0.2rem 0.5rem !important;
}

.compact tr th {
    padding: 0.3rem 0.5rem !important;
}

.compact .p-toolbar {
    padding: 0.6rem 0.3rem !important;
}

.compact .p-tabview-panels {
    padding: 0.5rem;
}

button.small-button {
    padding: 0rem 0rem !important;
}

.p-inputnumber-button {
    padding: 0rem !important;
}

@for $diff from 5 through 40 {
    .full-height-#{$diff*20}x, .full-height-#{$diff*20} > div.p-treetable, .full-height-#{$diff*20} > div.p-tree, .full-height-#{$diff*20} > div.p-tabview, .full-height-#{$diff*20} > .p-table, .full-height-#{$diff*20} > p-table > .p-datatable, .full-height-#{$diff*20} > .p-datatable, .full-height-#{$diff*20} > .p-listbox {
        height: calc(100vh - #{$diff*20}px);
        overflow-y: auto;
    }
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
}

.flex-item-fixed {
    flex-grow: 0;
}

.flex-item-fill {
    flex-grow: 1;
    overflow-x: clip;
    overflow-y: clip;
}

.data-view-100p > .p-tree {
    height: 100%;
    overflow-y: auto;
}

.tree-items-full-width .p-treenode-label {
    flex-grow: 1;
}

.tab-view-no-pad > .p-tabview > .p-tabview-panels {
    padding: 0;
}

.bold {
    font-weight: bold;
}

.no-resize {
    resize: none;
}

.transparent {
    background: transparent !important;
}

.p-column-filter > button > span {
    font-size: 11px !important;
}

.p-datatable-header {
    padding: 0.5rem 0.5rem !important;
}

.p-datatable-striped .p-datatable-tbody > tr:nth-child(even):not(.p-highlight) {
    background: #fcfcfc;
}

.p-treetable .p-treetable-tbody > tr:nth-child(even):not(.p-highlight) {
    background: #fcfcfc;
}

.ace_editor {
    font: 13px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace;
}

//Breadcrumbs, die keinen Link haben
.p-breadcrumb .p-menuitem-link:not([href]) {
    cursor: default !important;
    text-decoration: none !important;
    color: inherit !important;
}

.p-breadcrumb-list .p-menuitem-separator {
    margin: 0px 5px;
}

.p-fieldset .p-fieldset-legend {
    padding: 0.5rem;
}

.p-contextmenu {
    width: 300px;
}

.p-tieredmenu {
    width: 300px;
}

//Bugfix PrimeNG 16
.p-password eyeicon, eyeslashicon {
    right: 0.5rem;
}

//Sonst werden Button-Icons von Tabellenzellen mit gleicher Farbe wie der Hintergrund dargestellt
.p-datatable .p-datatable-tbody > tr.p-highlight .p-button.p-button-text {
    color: white !important;
}

.display-block {
    display: block;
}

.overflow-hidden {
    overflow: hidden;
}
